<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <h1>Knjige amortizacija</h1>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/amortization_books/create">
                    <b-btn variant="primary">Nova knjiga amortizacija</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="amortizationBooks">
            <b-col>
                <osta-table position
                            :items="amortizationBooks"
                            :fields="amortizationBooksFields"
                            @rowClicked="goToAmortizationBook">
                    <template slot="document_date" slot-scope="data">
                        {{data.item['document_date'] | moment("DD.MM.YYYY.")}}
                    </template>
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'ClientsAmortizationBooks',
        data() {
            return {
                amortizationBooks: null,
                amortizationBooksFields: [
                    {
                        key: 'period',
                        label: 'Period',
                        sortable: true
                    }
                ]
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToAmortizationBook(id) {
                this.$router.push('/amortization_books/' + id);
            },
            getAmortizationBooks() {
                axiOsta.post(api.clients + 'amortization_books', {
                    client_id: this.selectedClient.id
                }).then(response => {
                    if (response.status === 200) {
                        this.amortizationBooks = response.data;
                    }
                })
            }
        },
        created() {
            if (this.selectedClient != null) {
                this.getAmortizationBooks();
            }
        },
        watch: {
            selectedClient: function (newValue, oldValue) {
                if (oldValue == null) {
                    this.getAmortizationBooks();
                }
            },
        }
    }
</script>
